































































import { dialogRoute, DIALOG_NAMES } from "@/routenames";
import { api as auditApi } from "@/store/modules/audit";
import { api as configApi } from "@/store/modules/configuration";

import {
  MeasurePolicyPerFindingType,
  TranslateableText,
} from "@auditcloud/shared/lib/schemas";
import {
  FindingTypeMap,
  MeasureType,
} from "@auditcloud/shared/lib/types/ItemTypes";
import {
  MeasurePolicyViolation,
  violationLevelSortWorstToBest,
} from "@auditcloud/shared/lib/utils/audit/measurePolicies";
import { createMeasurePolicyFilterValue } from "@auditcloud/shared/lib/utils/filter/utils";
import { Dictionary, forEach, values } from "lodash";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";

type MeasurePolicyViolationRepresentation = {
  findingsCount: number;
  violationLevel: "warning" | "strict";
  requiredFindingTypeNames: string;
  measureTypeName: TranslateableText;
  measureTypeId: string;
};

type ViolationAlertType = "error" | "warning" | "info";

@Component({
  components: {},
})
export default class AMeasurePolicyViolations extends Vue {
  @Prop({
    type: Object,
    default: null,
  })
  measurePoliciesStatus!: null | {
    type: MeasurePolicyPerFindingType["policyType"];
    level: MeasurePolicyPerFindingType["policies"][string][string];
  };

  @Prop({
    default: [],
    type: Array,
  })
  measurePolicyViolations!: MeasurePolicyViolation[];

  @Getter(auditApi.getters.getMappedFindingTypes, {
    namespace: auditApi.namespace,
  })
  mappedFindingTypes!: FindingTypeMap;

  @Getter(configApi.getters.getMeasureTypes, {
    namespace: configApi.namespace,
  })
  measureTypes!: MeasureType[];

  get alertTypesByViolationType(): {
    strict: ViolationAlertType;
    warning: ViolationAlertType;
  } {
    const isBlocking = this.measurePoliciesStatus?.type === "blocking";

    return {
      strict: isBlocking ? "error" : "warning",
      warning: isBlocking ? "warning" : "info",
    };
  }

  get measurePolicyViolationsRepresentation(): MeasurePolicyViolationRepresentation[] {
    const result: Dictionary<MeasurePolicyViolationRepresentation> = {};

    forEach(this.measurePolicyViolations, violation => {
      const key = `${violation.measureTypeId}${violation.violationLevel}`;

      const findingTypeName = this.$ft(
        this.mappedFindingTypes[violation.findingTypeId]?.text ?? "UNKNOWN"
      );
      const measureTypeName = this.$ft(
        this.measureTypes.find(v => v.id === violation.measureTypeId)?.text ??
          "UNKNOWN"
      );

      if (key in result) {
        result[key].findingsCount += violation.findingCount;
        const concattenatedFindingTypeNames = result[
          key
        ].requiredFindingTypeNames.concat(`, ${findingTypeName}`);
        result[key].requiredFindingTypeNames = concattenatedFindingTypeNames;
      } else {
        result[key] = {
          findingsCount: violation.findingCount,
          violationLevel: violation.violationLevel,
          requiredFindingTypeNames: findingTypeName,
          measureTypeName,
          measureTypeId: violation.measureTypeId,
        };
      }
    });

    return values(result).sort(violationLevelSortWorstToBest);
  }

  get hasStrictPolicyViolations() {
    return this.strictMeasurePolicyViolations.length > 0;
  }

  get strictMeasurePolicyViolations() {
    return this.measurePolicyViolations.filter(
      v => v.violationLevel === "strict"
    );
  }

  emitMeasurePolicyFilterValue(
    violation: MeasurePolicyViolationRepresentation
  ) {
    const { measureTypeId, violationLevel } = violation;
    this.$emit(
      "set-filter-value",
      createMeasurePolicyFilterValue(measureTypeId, violationLevel)
    );
  }

  openMeasuresCreateDialog() {
    const routeName = this.$route.name ?? "error";
    const { auditId } = this.$route.params;

    if (auditId) {
      this.$router.push({
        name: dialogRoute(routeName, DIALOG_NAMES.MEASURES_CREATE_DIALOG),
        params: {
          auditId,
        },
      });
    }
  }
}
