






































































































































































































































































































































































































































































































































































































































































































































































































import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { Action, Getter, Mutation } from "vuex-class";

import { api } from "@/store/modules/audit";
import { api as auditResultApi } from "@/store/modules/auditResult";
import { api as measuresApi } from "@/store/modules/measures";
import { api as configApi } from "@/store/modules/configuration";

import { api as confApi } from "@/store/modules/configuration";
import { AuditWorkflowStepInfo } from "@/store/modules/audit/types";

import { AuditItem } from "@auditcloud/shared/lib/schemas";
import { IUserRef } from "@auditcloud/shared/lib/types/UserRef";

import { DIALOG_NAMES, dialogRoute, ROUTE_NAMES } from "@/routenames";
import { nullable, idable } from "@auditcloud/shared/lib/types/common";
import { MeasurePolicyPerFindingType } from "@auditcloud/shared/lib/schemas";
import {
  FindingTypeMap,
  MeasureType,
} from "@auditcloud/shared/lib/types/ItemTypes";

import {
  typeIsArrayOf,
  typeIsVdaMatrixEntry,
} from "@auditcloud/shared/lib/utils/type-guards";
import { cloneDeep, isString, entries, isUndefined } from "lodash";

import {
  emptyVdaMatrixData,
  VdaMatrixData,
} from "@auditcloud/shared/lib/components/types";
import AAuditTransitionWarning from "@/components/widgets/AAuditTransitionWarning.vue";
import AMeasurePolicyViolations from "@/components/snippets/AMeasurePolicyViolations.vue";
import {
  checkValidVda,
  prepareVdaMatrixData,
  AuditMetadataClient,
} from "@/types/Audit";
import { AuditStatusId } from "@auditcloud/shared/lib/constants";
import { AuditClassClient } from "@auditcloud/shared/lib/types/AuditClass";
import { AuditItemsInConflictState } from "@/store/modules/audit/getters";
import { auditStatusId2AuditWorkflowStates } from "@auditcloud/shared/lib/utils";
import { calcMeasureDueDate } from "@auditcloud/shared/lib/utils/measure/dueDate";
import { MissedConstraintResultData } from "@/store/modules/auditResult/getters";
import {
  isSummaryAndConfigConflictFree,
  isSummaryEmpty,
} from "@auditcloud/shared/lib/utils/audit/summary";
import { MeasurePolicyViolation } from "@auditcloud/shared/lib/utils/audit/measurePolicies";
import { api as usersApi } from "@/store/modules/users";
import { Filter } from "@auditcloud/shared/lib/utils/filter/types";
import { createMeasurePolicyFilterValue } from "@auditcloud/shared/lib/utils/filter/utils";
import { FILTER_MEASURE_POLICIES } from "@auditcloud/shared/lib/utils/filter/AuditItemListManipulatorIds";

@Component({
  components: { AAuditTransitionWarning, AMeasurePolicyViolations },
})
export default class AAuditTransition extends Vue {
  loading: boolean = false;
  nzTextLazy: string | null = null;
  responsible: any = null;
  comment: string = "";
  vda_object: VdaMatrixData = emptyVdaMatrixData();
  auditStatus: string | null = null;
  reviewDate: string = "";
  finalDate: string = "";
  auditDate: string[] = [];

  @Action(auditResultApi.actions.loadAuditResult, {
    namespace: auditResultApi.namespace,
  })
  loadAuditResult!: (payload: {
    lang: string;
    auditId: string;
  }) => Promise<void>;

  @Getter(auditResultApi.getters.getMissedConstraints, {
    namespace: auditResultApi.namespace,
  })
  missedConstraints!: MissedConstraintResultData[];

  @Getter(api.getters.getWorkflowSteps, {
    namespace: api.namespace,
  })
  workflowStepsAll!: AuditWorkflowStepInfo[];

  get workflowSteps() {
    return this.workflowStepsAll.filter(
      v => v.statusId !== AuditStatusId.Canceled
    );
  }

  get nzText(): string {
    const findingtypeId = this.finalizeExecutionFindingTypeId;
    const placeholderText = findingtypeId
      ? this.$ct(this.mappedFindingTypes[findingtypeId].placeholder_text)
      : null;
    return this.nzTextLazy ?? placeholderText ?? "";
  }

  set nzText(val: string) {
    this.nzTextLazy = val;
  }

  @Getter(api.getters.getIsVdaMatrixRequired, {
    namespace: api.namespace,
  })
  isVdaMatrixRequired!: boolean;

  @Getter(api.getters.getFinalizeExecutionFindingTypeId, {
    namespace: api.namespace,
  })
  finalizeExecutionFindingTypeId!: nullable<string>;

  @Getter(api.getters.getAuditClass, {
    namespace: api.namespace,
  })
  auditClass!: nullable<AuditClassClient>;

  @Getter(api.getters.getMappedFindingTypes, {
    namespace: api.namespace,
  })
  mappedFindingTypes!: FindingTypeMap;

  @Getter(configApi.getters.getMeasureTypes, {
    namespace: configApi.namespace,
  })
  measureTypes!: MeasureType[];

  @Getter(api.getters.getHasSelectedAuditItemIds, {
    namespace: api.namespace,
  })
  hasSelectedAuditItemIds!: boolean;

  @Getter(confApi.getters.areReportingDatesRequired, {
    namespace: confApi.namespace,
  })
  areReportingDatesRequired!: boolean;

  @Getter(api.getters.getAuditItemsInConflictState, {
    namespace: api.namespace,
  })
  auditItemsInConflictState!: AuditItemsInConflictState;

  @Getter(api.getters.getMaturityEnabled, {
    namespace: api.namespace,
  })
  maturityEnabled!: boolean;

  get hasFinalDate() {
    return isString(this.auditFinalDate);
  }

  get hasReviewDate() {
    return isString(this.auditReviewDate);
  }

  get areReportingDatesValid() {
    return (
      !this.areReportingDatesRequired ||
      (this.areReportingDatesRequired &&
        this.hasFinalDate &&
        this.hasReviewDate)
    );
  }

  get finalizeExecutionFindingType() {
    if (
      this.finalizeExecutionFindingTypeId !== null &&
      this.mappedFindingTypes[this.finalizeExecutionFindingTypeId]
    ) {
      return this.mappedFindingTypes[this.finalizeExecutionFindingTypeId].text;
    } else {
      return "Unbekannt";
    }
  }

  @Getter(api.getters.getAuditStatus, {
    namespace: api.namespace,
  })
  liveAuditStatus!: string | null;

  @Getter(api.getters.getAuditMetadata, {
    namespace: api.namespace,
  })
  auditMetadata!: AuditMetadataClient | null;

  get auditId() {
    return this.auditMetadata?.id ?? null;
  }

  @Getter(api.getters.isAuditTransitionDirty, {
    namespace: api.namespace,
  })
  transitionDirty!: boolean;

  @Getter(api.getters.getAuditItems, {
    namespace: api.namespace,
  })
  auditItems!: Array<idable<AuditItem>>;

  @Getter(api.getters.getUnAnsweredAuditItems, {
    namespace: api.namespace,
  })
  unanswerdAuditItems!: Array<idable<AuditItem>>;

  @Getter(api.getters.getAuditItemsWithMissingMaturity, {
    namespace: api.namespace,
  })
  auditItemsWithMissingMaturity!: Array<idable<AuditItem>>;

  @Getter(api.getters.getUnassignedFindingsCount, {
    namespace: api.namespace,
  })
  freeFindingsCount!: number;

  @Getter(api.getters.getMeasurePolicyViolations, {
    namespace: api.namespace,
  })
  measurePolicyViolations!: MeasurePolicyViolation[];

  @Getter(api.getters.getMeasurePoliciesStatus, {
    namespace: api.namespace,
  })
  measurePoliciesStatus!: null | {
    type: MeasurePolicyPerFindingType["policyType"];
    level: MeasurePolicyPerFindingType["policies"][string][string];
  };

  get isMeasurePolicyBlockingTransition() {
    return (
      this.measurePoliciesStatus?.type === "blocking" &&
      this.measurePoliciesStatus?.level === "strict"
    );
  }

  @Getter(measuresApi.getters.getOpenMeasureCount, {
    namespace: measuresApi.namespace,
  })
  openMeasureCount!: number;

  @Getter(configApi.getters.selfAssessmentFeatureEnabled, {
    namespace: configApi.namespace,
  })
  selfAssessmentFeatureEnabled!: boolean;

  @Getter(api.getters.getSelfAssessmentCompleted, { namespace: api.namespace })
  selfAssessmentCompleted!: boolean;

  @Getter(api.getters.getSelfAssessmentIncluded, { namespace: api.namespace })
  selfAssessmentIncluded!: boolean;

  get hasAuditDate() {
    return this.auditDates.length > 0;
  }

  get auditItemCount() {
    return this.auditItems.length;
  }

  get hasResponsible(): boolean {
    // responsible
    return this.auditMetadata?.responsible instanceof Object;
  }

  get auditParticipants(): IUserRef[] {
    return this.auditMetadata?.audit_participants ?? [];
  }

  get answerdAuditItemCount() {
    return this.auditItemCount - this.unanswerdAuditItems.length;
  }

  get answeredMaturitiesCount() {
    return this.auditItemCount - this.auditItemsWithMissingMaturity.length;
  }

  get auditStartDate() {
    return this.auditMetadata?.auditing_date_start;
  }

  get auditDates() {
    return this.auditMetadata?.auditing_date ?? [];
  }

  get auditReviewDate(): string | null {
    return this.auditMetadata?.due_date_review ?? null;
  }

  get auditFinalDate(): string | null {
    return this.auditMetadata?.due_date_final ?? null;
  }

  get assignedToQueryAction() {
    return {
      name: usersApi.actions.queryUser,
      namespace: usersApi.namespace,
    };
  }

  get hasFreeFindings() {
    return this.freeFindingsCount > 0;
  }

  @Watch("auditReviewDate", { immediate: true })
  onAuditReviewDateChanged() {
    if (this.auditReviewDate === null) {
      const initialReviewDate = calcMeasureDueDate(
        this.auditMetadata,
        this.auditClass,
        "review"
      )
        .toISOString()
        .substring(0, 10);

      this.reviewDate = initialReviewDate;
    } else {
      this.reviewDate = this.auditReviewDate;
    }
  }

  @Watch("auditFinalDate", { immediate: true })
  onAuditFinalDateChanged() {
    if (this.auditFinalDate === null) {
      const initialReviewDate = calcMeasureDueDate(
        this.auditMetadata,
        this.auditClass,
        "final"
      )
        .toISOString()
        .substring(0, 10);

      this.finalDate = initialReviewDate;
    } else {
      this.finalDate = this.auditFinalDate;
    }
  }

  @Watch("auditDates", { immediate: true })
  onAuditDateChanged(newVal: string[]) {
    if (newVal.length > 0) {
      this.auditDate = newVal;
    } else {
      this.auditDate = [new Date().toISOString().substr(0, 10)];
    }
  }

  @Mutation(api.mutations.SET_AUDIT_TRANSITION_DIRTY, {
    namespace: api.namespace,
  })
  setTransitionDirty!: (payload: boolean) => void;

  @Mutation(api.mutations.SET_FILTER_SETTING, {
    namespace: api.namespace,
  })
  setFilterSetting!: (filter: Filter) => void;

  @Action(api.actions.setDefaultAnswerForUnanswerdAuditItems, {
    namespace: api.namespace,
  })
  setDefaultAnswerForUnanswerdAuditItems!: (paylad: {
    text?: string;
  }) => Promise<any>;

  get workflowStep(): number {
    console.log("workflowStep:changed", this.auditStatus);
    const res = parseInt(this.auditStatus || "", 10);
    if (isNaN(res)) {
      return 0;
    } else {
      return res;
    }
  }

  get next(): null | AuditWorkflowStepInfo {
    const res = this.workflowSteps[this.workflowStep];
    if (res && this.workflowSteps[this.workflowStep + 1]) {
      return res;
    } else {
      return null;
    }
  }

  get translatedNextText() {
    if (this.next !== null) {
      return this.$t(this.next.transitionText);
    } else {
      return null;
    }
  }

  get validVda(): boolean {
    return checkValidVda(this.vda_object);
  }

  get vda(): VdaMatrixData {
    return prepareVdaMatrixData(this.auditMetadata);
  }

  @Action(api.actions.changeToNextAuditStatus, { namespace: api.namespace })
  changeToNextAuditStatus!: (payload: {
    from: string;
    comment: string;
  }) => Promise<string>;

  @Action(api.actions.activateConflictFilter, { namespace: api.namespace })
  activateConflictFilter!: () => Promise<void>;

  @Action(api.actions.activateUnansweredMaturitiesFilter, {
    namespace: api.namespace,
  })
  activateUnansweredMaturitiesFilter!: () => Promise<void>;

  @Action(api.actions.updateSingleMetadataField, { namespace: api.namespace })
  updateSingleMetadataField!: (payload: {
    fieldPath: string;
    value: any;
  }) => Promise<void>;

  @Action(api.actions.updateAuditMetadata, { namespace: api.namespace })
  updateAuditMetadata!: (payload: {
    id: string;
    data: { [fieldName: string]: any };
    activity: any;
  }) => Promise<void>;

  @Action(api.actions.completeSelfAssessment, { namespace: api.namespace })
  completeSelfAssessment!: () => Promise<any>;

  async changeState() {
    const nextStep = this.workflowStep + 1;
    const comment = this.comment.trim();

    this.changeToNextAuditStatus({
      from: String(this.workflowStep),
      comment,
    }).then(nextStatusId => {
      console.assert(
        nextStatusId === String(nextStep),
        "Expect nextStatusId an nextStep to be equal",
        nextStatusId,
        nextStep
      );
      this.$router.push({
        name: this.workflowSteps[nextStep].route,
      });
    });
  }

  storeResponsible() {
    console.log("STORE", this.auditMetadata?.id);
    if (
      this.responsible instanceof Object &&
      typeof this.auditMetadata?.id === "string"
    ) {
      this.loading = true;
      this.updateAuditMetadata({
        id: this.auditMetadata.id,
        data: {
          responsible: this.responsible,
        },
        activity: null,
      })
        .then(() => {})
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }

  storeVda() {
    console.log("STORE", this.auditMetadata?.id);
    if (
      typeIsArrayOf(this.vda_object.processes, isString) &&
      typeIsArrayOf(this.vda_object.products, isString) &&
      typeIsArrayOf(this.vda_object.matrix, typeIsVdaMatrixEntry) &&
      this.vda_object.products.length >= 1 &&
      this.vda_object.processes.length >= 1 &&
      this.vda_object.matrix.length >= 1 &&
      typeof this.auditMetadata?.id === "string"
    ) {
      this.loading = true;
      this.updateAuditMetadata({
        id: this.auditMetadata.id,
        data: {
          vda: this.vda_object,
        },
        activity: null,
      })
        .then(() => {})
        .catch(err => {
          console.error(err);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }

  storeReviewDate() {
    this.updateSingleMetadataField({
      value: this.reviewDate,
      fieldPath: "due_date_review",
    });
  }

  storeFinalDate() {
    this.updateSingleMetadataField({
      value: this.finalDate,
      fieldPath: "due_date_final",
    });
  }

  storeAuditDate() {
    this.updateSingleMetadataField({
      value: this.auditDate,
      fieldPath: "auditing_date",
    });
  }

  bulkSetFindings() {
    this.loading = true;
    const text = this.nzText.trim() !== "" ? this.nzText.trim() : undefined;

    this.setDefaultAnswerForUnanswerdAuditItems({ text })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        this.loading = false;
      });
  }

  openReportPreperationDialog() {
    const params = this.$route.params;
    const routeName = this.$route.name ?? "error";
    const auditId = params.auditId ?? null;

    if (typeof auditId === "string") {
      this.$router.push({
        name: dialogRoute(routeName, DIALOG_NAMES.REPORTING_DIALOG),
        params: {
          auditId,
        },
      });
    }
  }

  openMetadataDialog() {
    const params = this.$route.params;
    const routeName = this.$route.name ?? "error";
    const auditId = params.auditId ?? null;

    if (typeof auditId === "string") {
      this.$router.push({
        name: dialogRoute(routeName, DIALOG_NAMES.AUDIT_INFO_DIALOG),
        params: {
          auditId,
        },
      });
    }
  }

  async setConflictFilter() {
    await this.activateConflictFilter();
    this.$emit("close");
  }

  async setMaturitiesMissingFilter() {
    await this.activateUnansweredMaturitiesFilter();
    this.$emit("close");
  }

  setMeasurePolicyFilter(
    value: ReturnType<typeof createMeasurePolicyFilterValue>
  ) {
    this.setFilterSetting({
      aggregationId: FILTER_MEASURE_POLICIES,
      value,
    });
    this.$emit("close");
  }

  async openWrapup() {
    const params = this.$route.params;
    const auditId = params.auditId ?? null;

    if (typeof auditId === "string") {
      await this.$router.push({
        name: ROUTE_NAMES.AUDITWRAPUP,
        params: {
          auditId,
        },
      });
      await this.activateConflictFilter();
    }
  }

  endSelfAssessment() {
    this.loading = true;

    this.completeSelfAssessment()
      .then(data => {
        console.log("Ended Self Assessment", data);
        this.loading = false;
      })
      .catch(err => {
        console.log("Error while ending Self Assessment", err);
        this.loading = false;
      });
  }

  mounted() {
    this.vda_object = cloneDeep(this.vda);
    const auditId = this.auditId;
    console.log(
      "AAuditTransition:mounted",
      this.vda_object,
      this.vda,
      this.auditStatus,
      this.liveAuditStatus,
      this.answerdAuditItemCount,
      this.auditItemCount,
      auditId
    );
    this.auditStatus = this.liveAuditStatus;
  }

  @Watch("transitionValid", {
    immediate: true,
  })
  onValidChanged(newVal: boolean) {
    this.setTransitionDirty(!newVal);
  }

  @Watch("transitionWithoutMissedConstraintsValid", {
    immediate: true,
  })
  onTransitionWithoutMissedConstraintsValidChanged(newVal: boolean) {
    this.reloadAuditResult();
  }

  @Watch("auditItems", { immediate: true, deep: true })
  onAuditItemsChange(newVal: unknown) {
    this.reloadAuditResult();
  }

  get auditItemsIdsWithConflict(): string[] {
    const auditItemsIdsWithConflict = entries(this.auditItemsInConflictState)
      .filter(([, conflictInfo]) => {
        return conflictInfo !== null && conflictInfo.length > 0;
      })
      .map(([auditItemId]) => auditItemId);
    return auditItemsIdsWithConflict;
  }

  get areAllQuestionsConflictFree() {
    return this.auditItemsIdsWithConflict.length === 0;
  }

  get isStatusPlanning() {
    return this.auditStatus === AuditStatusId.Planning;
  }
  get isStatusPreparation() {
    return this.auditStatus === AuditStatusId.Preparation;
  }
  get isStatusExecution() {
    return this.auditStatus === AuditStatusId.Execution;
  }
  get isStatusWrapup() {
    return this.auditStatus === AuditStatusId.Wrapup;
  }
  get isStatusReporting() {
    return this.auditStatus === AuditStatusId.Reporting;
  }
  get isStatusMeasureActions() {
    return this.auditStatus === AuditStatusId.MeasureActions;
  }
  get isStatusCompleted() {
    return this.auditStatus === AuditStatusId.Completed;
  }

  get showUnansweredQuestionsAction() {
    return (
      (this.isStatusWrapup || this.isStatusReporting) &&
      this.answerdAuditItemCount !== this.auditItemCount
    );
  }

  get showMissingMaturityWarning() {
    return (
      this.maturityEnabled &&
      this.answeredMaturitiesCount !== this.auditItemCount
    );
  }

  get showConflictsWarning() {
    return (
      (this.isStatusWrapup || this.isStatusReporting) &&
      !this.areAllQuestionsConflictFree
    );
  }

  get isTransitionToPreparationValid() {
    if (this.isStatusPlanning) {
      return true; // There are no constrains for the planning
    } else {
      return true;
    }
  }

  get isTransitionToExecutionValid() {
    if (this.isStatusPreparation) {
      return (
        this.auditItemCount > 0 &&
        this.hasAuditDate &&
        (!this.isVdaMatrixRequired ||
          (this.vda.processes.length > 0 && this.vda.products.length > 0))
      );
    } else {
      return true;
    }
  }

  get isTransitionToWrapupValid() {
    if (this.isStatusExecution) {
      return this.hasResponsible && !this.selfAssessmentOutstanding;
    } else {
      return true;
    }
  }

  get isTransitionToReportingValid() {
    if (this.isStatusWrapup) {
      return (
        !this.isMeasurePolicyBlockingTransition &&
        this.auditParticipants.length > 0 &&
        this.areReportingDatesValid &&
        !this.hasFreeFindings &&
        this.answerdAuditItemCount === this.auditItemCount &&
        this.areAllQuestionsConflictFree &&
        !this.showMissingMaturityWarning
      );
    } else {
      return true;
    }
  }

  get selfAssessmentOutstanding() {
    return (
      this.selfAssessmentFeatureEnabled &&
      this.selfAssessmentIncluded &&
      !this.selfAssessmentCompleted
    );
  }

  get openSummaryTopics() {
    const summaryItemConfig = this.auditClass?.summaryItemConfig ?? [];
    const summary = this.auditMetadata?.summary ?? {};

    return summaryItemConfig
      .filter(configItem => {
        return (
          isString(summary) || summary[configItem.model].trim().length === 0
        );
      })
      .map(configItem => configItem.name);
  }

  get isSummaryValid() {
    const summary = this.auditMetadata?.summary;
    const summaryItemConfig = this.auditClass?.summaryItemConfig;
    if (isUndefined(summary) || isUndefined(summaryItemConfig)) {
      return false;
    }

    return (
      isSummaryAndConfigConflictFree(summary, summaryItemConfig) &&
      !isSummaryEmpty(summary)
    );
  }

  get isTransitionToMeasureActionsValid() {
    if (this.isStatusReporting) {
      return (
        !this.isMeasurePolicyBlockingTransition &&
        !this.hasFreeFindings &&
        this.isSummaryValid &&
        this.answerdAuditItemCount === this.auditItemCount &&
        this.areAllQuestionsConflictFree
      );
    } else {
      return true;
    }
  }

  get isTransitionToCompleteValid() {
    if (this.isStatusMeasureActions) {
      return this.openMeasureCount === 0;
    } else {
      return true;
    }
  }

  get transitionWithoutMissedConstraintsValid() {
    return (
      this.isTransitionToPreparationValid &&
      this.isTransitionToExecutionValid &&
      this.isTransitionToWrapupValid &&
      this.isTransitionToReportingValid &&
      this.isTransitionToMeasureActionsValid &&
      this.isTransitionToCompleteValid
    );
  }

  get constraintsValid() {
    if (this.isStatusReporting || this.isStatusWrapup) {
      return this.missedConstraints.length === 0;
    } else {
      return true;
    }
  }

  get transitionValid() {
    return (
      this.transitionWithoutMissedConstraintsValid && this.constraintsValid
    );
  }

  get transitionActionInformation() {
    const workflowState = auditStatusId2AuditWorkflowStates(
      this.auditStatus ?? AuditStatusId.Planning
    );

    const configuredActions = this.auditClass?.auditTransitionActions ?? [];

    const activeActions = configuredActions.filter(
      action => action.fromAuditStates?.includes(workflowState) ?? "false"
    );

    console.assert(
      activeActions.every(
        action =>
          isString(action.workflowTransitionId) &&
          action.workflowTransitionId.length > 0
      ),
      "Warn if a new transition action is added"
    );

    if (activeActions.length > 0) {
      return this.$t(
        "components.widgets.sidebar.audit_transition.info_transition_action_measure_transition"
      );
    } else {
      return null;
    }
  }

  beforeDestroy() {
    console.log("AAuditTransitoin:beforeDestroy");
    this.setTransitionDirty(false);
  }

  created() {
    this.$emit(
      "title-changed",
      this.next ? this.next.text : "Audit Transition"
    );
  }

  reloadAuditResult() {
    const auditId = this.auditId;
    if (
      auditId &&
      (this.liveAuditStatus === AuditStatusId.Wrapup ||
        this.liveAuditStatus === AuditStatusId.Reporting)
    ) {
      this.loadAuditResult({ auditId, lang: this.$i18n.locale });
    }
  }
}
